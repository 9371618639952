import {CollectionInterface} from "~/repositories/DefaultRepository";

export default class CategoryRepository {
    private static readonly DATA: CollectionInterface<CategoryInterface> = {
        totalCount: 2,
        items: [
            {id: 'N3WkKxAq8kqfyuhBAMsmqu', name: 'Znalezione'},
            {id: 'JYynXb1qUoqqCDAtxYwebA', name: 'Zgubione'},
        ]
    }

    public findOneById(id: string): CategoryInterface | null {
        const collection = this.find({id});

        if (collection.totalCount === 0) {
            return null;
        }

        if (collection.totalCount > 1) {
            throw Error('Non unique results!');
        }

        return collection.items[0];
    }

    public find(filter?: FilterInterface): CollectionInterface<CategoryInterface> {
        const items: CategoryInterface[] = CategoryRepository.DATA.items.filter((item) => {
            if (filter) {
                return filter?.id && filter.id === item.id;
            }

            return true;
        });

        return {
            totalCount: items.length,
            items: items
        };
    }
}

export interface FilterInterface {
    id?: string,
}

export interface CategoryInterface {
    id: string,
    name: string,
}
